import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1549388604-817d15aa0110', // Test image 1
    title: 'Placeholder 1',
    description: 'Description 1',
  },
  {
    img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3', // Test image 2
    title: 'Placeholder 2',
    description: 'Description 2',
  },
  {
    img: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6', // Test image 3
    title: 'Placeholder 3',
    description: 'Description 3',
  },
  // Your original images
  {
    img: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
    title: 'Title 1',
    description: 'Description 1',
  },
  {
    img: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
    title: 'Title 2',
    description: 'Description 2',
  },
  {
    img: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
    title: 'Title 3',
    description: 'Description 3',
  },
  // Add more image objects as needed
];

export default function TitlebarBelowImageList() {
  return (
    <div className="overflow-x-auto p-8" >
      <h2 className="collections-title text-center mb-4 text-[#001c25]">MUFA'S CATEGORIES</h2>
      <ImageList
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          transform: 'translateZ(0)',
          width: 'auto',
        }}
      >
        {itemData.map((item, index) => (
          <ImageListItem
            key={item.img}
            sx={{
              minWidth: 200,
              marginRight: index < itemData.length - 1 ? 2 : 0, // Added margin between images
            }}
          >
            <img
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
              className="object-cover w-full h-full"
            />
            <div className="bg-black bg-opacity-75 p-2 absolute bottom-0 left-0 right-0">
              <h4 className="text-white font-medium text-center">{item.title}</h4>
              <p className="text-yellow-500 font-normal text-center">{item.description}</p>
            </div>
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
