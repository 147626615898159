import React from "react";
import { Typography, Container, Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MetaData from "../component/layouts/MataData/MataData";
import TermsImage from "../Image/about/tc.jpg";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  about_us: {
    paddingTop: "8rem",
    paddingBottom: "4rem",
    backgroundColor: "white !important",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  container_12: {
    padding: "2rem",
    textAlign: "center",

    backgroundColor: "white !important",
    maxWidth: "100%",
  },
  image_about: {
    width: "100%",
    height: "auto",
    marginTop: "3rem",
    marginBottom: "2rem",
  },
  title_about: {
    color: "#414141",
    fontSize: "14px",
    padding: "2rem 1rem 2rem",
    fontFamily: "Roboto",
    fontWeight: "500 !important",
  },
  heading12_about: {
    fontSize: "1rem",
    padding: "2rem 1rem 2rem",
    fontWeight: "400 !important",
    color: "#414141",
    textAlign: "center",
  },
  introText_about: {
    maxWidth: "800px",

    lineHeight: "1.5",
    margin: "1.5rem 0",
    color: "#292929",
    fontSize: "1.2rem",
    fontWeight: "400 !important",
    textAlign: "justify",
    padding: "0.8rem 1rem",
  },
  infoText_about: {
    lineHeight: "1.5",
    margin: "2rem 0",
    color: "#292929",
    fontSize: "1rem",
    fontWeight: "400 !important",
    textAlign: "justify",
    padding: "0.8rem 1rem",
  },
  buttonContainer_about: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem 0",
    width: "100%",
    marginTop: "1rem",
  },
  button1_about: {
    backgroundColor: "#000000 !important",
    color: "white !important",
    width: "fit-content !important",
    padding: "0.8rem 2rem   !important",
    marginLeft: "3.3rem !important",
    borderRadius: "5px !important",
    "&:hover": {
      backgroundColor: "#ed1c24 !important",
      color: "white !important",
    },
  },
  button2_about: {
    backgroundColor: "#292929 !important",
    color: "white   !important",
    width: "fit-content     !important",
    padding: "0.8rem 2rem   !important",
    marginLeft: "1.3rem !important",
    borderRadius: "5px !important",
    "&:hover": {
      backgroundColor: "#ed1c24 !important",
      color: "white !important",
    },
  },
}));

const About_UsPage = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.about_us}>
        <MetaData title={"About Us"} />
        <Container className={classes.container_12}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <img
                src={TermsImage}
                alt="CricketWeapon"
                className={classes.image_about}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h2"
                component="h1"
                className={classes.title_about}
              >
                About Us
              </Typography>
              <Typography variant="body1" className={classes.introText_about}>
              There was a small but ambitious company known as Mufa Perfumes founded in 2014, the team had a passion for creating the most seductive fragrances combining traditional techniques with modern innovations to create an exceptional experience for their customers and set out to create a perfume line that would leave a lasting impression. It began with an exclusive range of non alcoholic fragrances for to the discerning tastes of people who appreciate the finer things in life.
              </Typography>
              <Typography variant="body1" className={classes.introText_about}>
              Over the years, Mufa Perfumes quickly gained an outstanding reputation in the realm of perfumery and evolved into Mufa International Pvt Ltd, the brand expanded its offerings to include alcohol-based perfumes and body deodorants focusing on quality and innovation. The company has continuously innovated and expanded its product line, from classic fragrances to modern blends, each scent is carefully crafted to meet the diverse needs of its customers which are not only pleasant, but also long-lasting and memorable.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={classes.container_12}>
          <Typography
            variant="h3"
            component="h1"
            className={classes.heading12_about}
          >
            Who We Are
          </Typography>
          <Typography variant="body1" className={classes.infoText_about}>
          There was a small but ambitious company known as Mufa Perfumes founded in 2014, the team had a passion for creating the most seductive fragrances combining traditional techniques with modern innovations to create an exceptional experience for their customers and set out to create a perfume line that would leave a lasting impression. It began with an exclusive range of non alcoholic fragrances for to the discerning tastes of people who appreciate the finer things in life.
          </Typography>
          <Typography variant="body1" className={classes.infoText_about}>
          The team at Mufa International Pvt Ltd is dedicated to the art of perfumery and is constantly searching for the best ingredients to experiment with new combinations and create new and unique fragrances that stand out from the rest. They believe that fragrances are an extension of personality and should reflect the essence of the person wearing them. Whether you are looking for a signature scent for every day or a special perfume for a special occasion, Mufa International Pvt Ltd has something for you. If you are focused on quality, innovation, and customer satisfaction or a business looking for a company to partner with to reach more customers around the world we are open to collaboration.
          </Typography>
          <Typography variant="body1" className={classes.infoText_about}>
          If you are looking for a company that is passionate about fragrances and committed to excellence, look no further than Mufa International Pvt Ltd. With us, you can be sure that you will receive a fragrance that is not only of the highest quality, but also a true reflection of your personalit
          </Typography>
        </Container>
        <Container className={classes.container_12}>
          <Typography
            variant="h3"
            component="h1"
            className={classes.heading12_about}
          >
            Our Mission
          </Typography>
          <Typography variant="body1" className={classes.infoText_about}>
            CricketWeapon is driven by the mission to provide high-quality
            cricket equipment and accessories at affordable prices. We aim to
            make cricket accessible to players worldwide and support their
            passion for the sport. Our mission is to offer a wide range of
            cricket equipment, including bats, balls, protective gear, and
            accessories, that meet the highest standards of quality and
            performance.
          </Typography>
          <Typography variant="body1" className={classes.infoText_about}>
            We are committed to continuously innovating and improving our
            product range to meet the evolving needs of cricketers. Our team of
            experts works closely with manufacturers and conducts rigorous
            quality testing to ensure that every product we offer delivers
            exceptional performance on the field. We believe that every player
            deserves the best tools to enhance their skills and achieve their
            cricketing goals.
          </Typography>

          <div className={classes.buttonContainer_about}>
            <Link
              to="/products"
              style={{ textDecoration: "none", color: "none" }}
            >
              <Button variant="contained" className={classes.button1_about}>
                Our Products
              </Button>
            </Link>
            <Link
              to="/contact"
              style={{ textDecoration: "none", color: "none" }}
            >
              <Button variant="contained" className={classes.button2_about}>
                Contact Us
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};

export default About_UsPage;
