import React from "react";
import {Link} from "react-router-dom";
import "./Privacy.css";
import MetaData from "../component/layouts/MataData/MataData";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
     <MetaData title={"Privacy Policy"} />
      <div className="container___">
        <h1>Privacy Policy for Mufa World</h1>
        <p style={{ fontSize: "16px", fontWeight: "600" }}>
          {" "}
          Introduction
        </p>
        <p>
        At https://www.mufaworld.com/ accessible from https://www.mufaworld.com/ , one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by https://www.mufaworld.com/and how we use it.
        </p>
        <h2>1. Information We Collect</h2>We collect several types of information from our users, including:
        <h3>1.1 Personal Information:</h3>
        <p>
        1.When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
        2.If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
        </p>
        <h3>Log Files:</h3>
        <p>
        https://www.mufaworld.com/follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information
        </p>
          <li>Provide customer support and respond to inquiries</li>
          <h2>Cookies:</h2><p>https://www.mufaworld.com/ uses cookies, which are small files downloaded to your computer, to improve your website experience. We use several different types of cookies, including session cookies, persistent cookies, and third-party cookies. Session cookies are temporary cookies that expire when you close your browser. Persistent cookies remain on your device until you erase them or they expire. Third-party cookies are placed by third-party services, such as Google Analytics, and are used to collect data about your online activity across different websites.
          </p>
        <h3>How We Use Your Information: </h3>
        <p>We use the information we collect in various ways, including to:

Provide, operate, and maintain our website Improve, personalize, and expand our website Understand and analyze how you use our website Develop new products, services, features, and functionality Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes Send you emails Find and prevent fraud</p>
       
        <h3>Log Files</h3>
        <p>
        We use log files to track website traffic and to gather demographic information. This information is used to analyze trends, administer the site, track users' movement on the website, and gather demographic information.
        </p>
       
        <h2>Cookies</h2>
        <p>
        We utilize cookies to enhance your experience on our website by remembering your login status, tracking your website activity, and personalizing your overall browsing experience. Additionally, we may use cookies to deliver targeted advertising.


        </p>
       
        <h2>Cookie Management</h2>
        <p>
        You have the option to disable cookies through the settings in your individual browser. For more detailed information on how to manage cookies using specific web browsers, please refer to the respective websites of those browsers.
        </p>
        <h2>Data Sharing</h2>
        <p>
        We prioritize the security and privacy of your personal information. Consequently, we do not share your personal data with third-party advertisers.
        </p>
        <h2>Data Retention</h2>
        <p>
        We retain your personal information for the duration of your account's existence. Even after you close your account, we maintain your personal data for a reasonable period to comply with legal obligations, settle disputes, and enforce our agreements.
        </p>
        <h2>Security Measures</h2>
        <p>
        To safeguard your personal information, we implement various security measures. We use secure protocols for communication and data transfer, and our servers are equipped with both physical and electronic security measures.</p>
        <h2>Your Rights</h2>
        <p>As a user, you have specific rights concerning your personal information:</p>
        <ul>
          <li>
          The Right to Rectification: If you believe any information we hold is inaccurate, you have the right to request corrections or completion.

          </li>
          <li>
          The Right to Erasure: You can request the deletion of your personal information under certain conditions.

          </li>
          <li>
          The Right to Restrict Processing: Under certain conditions, you can request limitations on the processing of your personal information.

          </li>
          <li>The Right to Object to Processing: You have the right to object to our processing of your personal information under certain conditions.
          </li>
        </ul>

        <h2>Contact Support</h2>
        <p>
          If you have any questions, concerns, or suggestions regarding this
          Privacy Policy, please contact us at {"Email: support@mufaworld.co.in,Phone: 01140516336 "}
          <Link to="/" style={{ textDecoration : "none" , color : "inherit" , fontWeight : 700}}>
            [insert contact information]
          </Link>
        </p>
        <p>
          By using the Mufaworld website and services, you agree to the
          collection, use, and disclosure of your information as described in
          this Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
