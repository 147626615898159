import React from 'react';
import Carousel from 'react-grid-carousel';

const FirstSlider = () => {
  return (
    <Carousel cols={4} rows={1} gap={10} loop>
      <Carousel.Item>
        <img width="100%" src="https://picsum.photos/800/600?random=1" alt="Random 1" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="https://picsum.photos/800/600?random=2" alt="Random 2" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="https://picsum.photos/800/600?random=3" alt="Random 3" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="https://picsum.photos/800/600?random=4" alt="Random 4" />
      </Carousel.Item>
      {/* Add more items as needed */}
    </Carousel>
  );
};

export default FirstSlider;
